<template>
    <v-row>
        <!-- <v-bottom-navigation color="primary" fixed class="d-inline-flex d-md-none" v-model="value">
            <v-slide-group show-arrows center-active>
                <v-slide-item v-for="m in menu" :key="m.link" v-slot="{ toggle }">
                    <v-btn @click="toggle" :to="m.to" exact exact-active-class="font-weight-bold teal lighten-5">
                        <v-icon>{{m.icon}}</v-icon>
                        <span>{{m.titulo_app}}</span>
                    </v-btn>
                </v-slide-item>
            </v-slide-group>
        </v-bottom-navigation>

        <v-col cols="12" class="d-none d-md-block">
            <v-toolbar elevation="1" height="80" rounded>
                <v-row>
                    <v-col class="py-0 my-1" cols="12">
                        <template v-for="m in menu">
                            <v-btn v-if="m.to != '/'" :key="m.link" :to="m.to" color="primary" exact text>{{m.titulo}}</v-btn>
                        </template>
                    </v-col>
                    <v-col class="pa-0 my-0 ml-n3 d-none d-md-block" cols="12">
                        <Breadcrumbs /> 
                    </v-col>
                </v-row>                
            </v-toolbar>                
        </v-col> -->       

        <v-col cols="12" lg="12">
            <router-view/>
        </v-col>
    </v-row>
</template>

<script>
// import Breadcrumbs from '../Widgets/Breadcrumbs'

export default {
    name : "PerfilContainer",
    // components : {Breadcrumbs},
    data : () => ({
        menu   : [],
        drawer : false,
        group  : null,
        value  : 0
    }),
    methods : {
        montarMenu() {
            let m = []

            m.push({titulo : 'Ínicio', titulo_app : 'Ínicio', icon : 'mdi-home-outline', to : '/'})
            m.push({titulo : '* Meu Perfil', titulo_app : 'Perfil', icon : 'mdi-account-multiple-outline', to : '/perfil'})

            this.menu = m
        }
    },
    created() {
        this.montarMenu()
    }
}
</script>

<style scoped>

</style>